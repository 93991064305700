import React, { useEffect, useState } from 'react'
import { Combobox } from '@headlessui/react'
import { useQuery, useMutation } from 'react-query'
import { getChallengeMap } from '../../../services/api/challenges'
import { setNonAdvanceableChallenges, getNonAdvanceableChallenges } from '../../../services/api/settings'
import { ChevronUpDownIcon } from '@heroicons/react/24/outline'
import { Challenge, ChallengeMap } from '../../../types'

const AdminNonAdvanceableChallengesSelector = () => {
  const [selectedChallenges, setSelectedChallenges] = useState<Challenge[]>([])
  const [query, setQuery] = useState('')
  const [challenges, setChallenges] = useState<Challenge[]>([])

  // Fetch challenges for the dropdown
  useEffect(() => {
    const fetchChallenges = async () => {
      const challengeMap: ChallengeMap = await getChallengeMap()
      setChallenges(Object.values(challengeMap)) // Convert map to array
    }

    fetchChallenges()
  }, [])

  // Fetch the current non-advanceable challenges
  const {
    data: currentNonAdvanceableChallenges,
    isLoading: isLoadingChallenges,
    error: fetchError,
  } = useQuery('nonAdvanceableChallenges', getNonAdvanceableChallenges, {
    staleTime: Infinity,
    onSuccess: (currentNonAdvanceableChallenges: string) => {
      console.log('Fetched non-advanceable challenges:', currentNonAdvanceableChallenges)
    },
  })

  // Sync selectedChallenges with fetched data and available challenges
  useEffect(() => {
    if (currentNonAdvanceableChallenges && challenges.length > 0) {
      const selectedIds = currentNonAdvanceableChallenges
        .replace(/[{}]/g, '') // Remove brackets if present
        .split(',')
        .map((id) => id.trim())

      const alreadySelected = challenges.filter((challenge) => selectedIds.includes(challenge.id))
      setSelectedChallenges(alreadySelected)
    }
  }, [currentNonAdvanceableChallenges, challenges])

  // Mutation for updating the backend
  const { mutate: mutateNonAdvanceableChallenges } = useMutation(setNonAdvanceableChallenges, {
    onSuccess: () => {
      console.log('Non-advanceable challenges updated successfully.')
    },
    onError: (error) => {
      console.error('Error updating non-advanceable challenges:', error)
    },
  })

  const handleSelectionChange = (newSelection: Challenge[]) => {
    setSelectedChallenges(newSelection)

    // Mutate the non-advanceable challenges on the backend
    mutateNonAdvanceableChallenges(newSelection.map((c) => c.id))
  }

  const filteredChallenges = query === '' ? challenges : challenges.filter((challenge) => challenge.title.toLowerCase().includes(query.toLowerCase()))

  if (isLoadingChallenges) {
    return <div>Loading...</div>
  }

  if (fetchError) {
    return <div>Error loading challenges.</div>
  }

  return (
    <div key="nonAdvanceableSelector" className="">
      <h2 className="text-lg font-bold">Non-Advanceable Challenges</h2>
      {selectedChallenges.length > 0 && (
        <ul className={'list-disc pl-4 text-sm '}>
          {selectedChallenges.map((selectedChallenge) => (
            <li className={'text-sm '} key={selectedChallenge.id}>
              {selectedChallenge.title}
            </li>
          ))}
        </ul>
      )}
      <Combobox value={selectedChallenges} onChange={handleSelectionChange} multiple>
        <div className="relative mt-2">
          <Combobox.Input
            className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(selectedChallenges: Challenge[]) => selectedChallenges.map((c) => c.title).join(', ')}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </Combobox.Button>

          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredChallenges.map((challenge) => (
              <Combobox.Option
                key={challenge.id}
                value={challenge}
                className={({ active, selected }) =>
                  `cursor-pointer select-none py-2 pl-10 pr-4 ${active ? 'bg-indigo-600 text-white' : selected ? 'bg-indigo-100 text-indigo-900' : 'text-gray-900'}`
                }
              >
                {({ selected }) => (
                  <>
                    <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{challenge.title}</span>
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </div>
      </Combobox>
    </div>
  )
}

export { AdminNonAdvanceableChallengesSelector }
