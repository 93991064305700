import { Challenge, ChallengeAchievementAssociation } from 'types'
import { post, get } from './helpers'
import { ShowPromoCodeSettingOptions } from './types'

export const setShowPromoCodeContactUs = async (value: boolean): Promise<boolean> => {
  console.log(`setShowPromoCodeContactUs: ${value}`)
  const res = await post<ShowPromoCodeSettingOptions>(`/admin/settings/showPromoCodeContactUs`, {
    showPromoCodeContactUs: value,
  })

  const returnValue = res.showPromoCodeContactUs ?? false

  return returnValue
}

export const getShowPromoCodeContactUs = async (): Promise<boolean> => {
  const res = await get<ShowPromoCodeSettingOptions>(`/admin/settings/showPromoCodeContactUs`)

  const returnValue = res.showPromoCodeContactUs ?? false

  return returnValue
}

export const setFeaturedChallenge = async (value: string, featuredNumber: number): Promise<boolean> => {
  console.log(`setFeaturedChallenge: ${value}`)
  const res = await post<boolean>(`/admin/settings/featuredChallenge`, {
    featuredChallenge: value,
    featuredNumber: featuredNumber,
  })

  const returnValue = res ?? false

  return returnValue
}

export const setNonAdvanceableChallenges = async (values: string[]): Promise<boolean> => {
  try {
    // we want to stringify the array of challenge ids
    const stringifiedValues = values.map((challengeId) => challengeId.toString())
    const res = await post<boolean>(`/admin/settings/nonAdvanceableChallenges`, {
      value: stringifiedValues,
    })

    const returnValue = res ?? false

    return returnValue
  } catch (error) {
    console.log(error)
    return false
  }
}

export const getNonAdvanceableChallenges = async (): Promise<Challenge[]> => {
  const res = await get<Challenge[]>(`/admin/settings/nonAdvanceableChallenges`)
  const returnValue = res
  return returnValue
}

export const getFeaturedChallenge = async (featuredNumber: number): Promise<Challenge> => {
  const res = await get<Challenge>(`/admin/settings/featuredChallenge$featuredNumber=${featuredNumber}`)

  const returnValue = res
  return returnValue
}

export const getFeaturedChallenges = async (): Promise<Challenge[]> => {
  const res = await get<Challenge[]>(`/admin/settings/featuredChallenges`)
  const returnValue = res
  return returnValue
}

export const getChallengeAchievementAssociations = async (): Promise<Map<string, ChallengeAchievementAssociation>> => {
  const res = await get<string>(`/admin/settings/challengeAchievementAssociations`)
  const returnValue = res
  console.log(`getChallengeAchievementAssociations API response: ${returnValue}`)
  //decode the json to an array of ChallengeAchievementAssociation
  const decoded: ChallengeAchievementAssociation[] = JSON.parse(returnValue)

  console.log(`getChallengeAchievementAssociations: ${decoded}`)

  // getChallengeAchievementAssociations: "\"[{challengeId:\"5tgsGPtYsAO7ch8ZAf0PYl\",achievementId:\"9ff34e91-c36b-45b0-b7e8-e4d478a55ce0\"}]\""
  const associationsMap = new Map<string, ChallengeAchievementAssociation>()
  decoded.forEach((association) => {
    associationsMap.set(association.challengeId, association)
  })

  return associationsMap
}

export const setChallengeAchievementAssociations = async (associationsMap: Map<string, ChallengeAchievementAssociation>): Promise<ChallengeAchievementAssociation[]> => {
  // json Serialize the associations map
  const associations = Array.from(associationsMap.values())
  console.log(`setChallengeAchievementAssociations: ${associations}`)
  const res = await post<ChallengeAchievementAssociation[]>(`/admin/settings/challengeAchievementAssociations`, associations)

  return res
}
