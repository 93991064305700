import React from 'react'
import Benefit from './Benefit'

const imagePathPrefix = 'sign-up/phil-phones'

const Marketing = () => {
  return (
    <>
      <div className="relative flex justify-center space-x-3 font-extrabold font-BebasNeueRegular mt-12">
        {/* Sparkles */}
        {/* {Array.from({ length: 10 }).map((_, i) => (
          <span
            key={i}
            className="sparkle absolute w-2 h-2 bg-gradient-to-r from-[#FFDE8E] to-[#E9AA34] rounded-full"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animationDelay: `${Math.random() * 2}s`,
            }}
          ></span>
        ))} */}
        <span className="text-4xl lg:text-7xl uppercase bg-clip-text text-transparent bg-gradient-to-r from-[#FFDE8E] to-[#E9AA34]">Your Lean Journey </span>
        <p className="text-4xl lg:text-7xl uppercase text-slate-50">Starts Here!</p>
      </div>

      <p className="text-2xl text-center font-medium text-slate-50 mt-4">From beginner to advanced, find a program that works for YOU!</p>

      <div className="rounded-3xl mx-auto mt-8 p-[6px] bg-gradient-to-br from-[#FFDE8E] via-[#E9AA34] to-[#50504f]">
        <div className="flex justify-center">
          <div className="flex flex-col rounded-3xl bg-white shadow-xl ring-1 ring-black/10">
            <img
              srcSet={`images/${imagePathPrefix}-2x.jpg 2013w, images/${imagePathPrefix}-1x.jpg 1006w`}
              src={`images/${imagePathPrefix}-2x.jpg`}
              alt="preview"
              className="rounded-t-3xl"
            />

            <div className="flex flex-col items-center p-4">
              <div className="space-y-2">
                <Benefit>
                  <p>Get unlimited access to ALL 40+ Lean Challenges & Programs!</p>
                  <p>- Over 1000 workouts for every level, goal, and transformation!</p>
                </Benefit>
                <Benefit>
                  <p>Achieve results with just 2-4 workouts a week in my NEW Flex Challenges!</p>
                </Benefit>
                <Benefit>
                  <p>Enjoy a full library of delicious recipes, customized just for you!</p>
                </Benefit>
                <Benefit>
                  <p>Instant access to On-Demand workouts, including prenatal, guided runs/walks, and more!</p>
                </Benefit>
              </div>

              <button
                className="lg:hidden bg-gradient-to-r from-coral to-coral-darker text-slate-50 font-extrabold text-lg my-4 h-12 w-full rounded-md shadow-md hover:ring ring-coral-darkest"
                onClick={() => {
                  document.getElementById('sign-up')?.scrollIntoView({ behavior: 'smooth', block: 'start' })
                  setTimeout(() => window.scrollBy({ top: -50, left: 0, behavior: 'smooth' }), 750)
                }}
              >
                Join Now!!!
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* CSS styles */}
      <style>{`
        .sparkle {
          animation: sparkle-animation 2s infinite ease-in-out;
          opacity: 0;
        }

        @keyframes sparkle-animation {
          0%,
          100% {
            opacity: 0;
            transform: scale(0.5);
          }
          50% {
            opacity: 1;
            transform: scale(1.5);
          }
        }
      `}</style>
    </>
  )
}

export { Marketing }
