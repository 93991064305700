import React, { useState, useEffect } from 'react'
import { useSignUpContext } from '../SignUpContext'
import FormWrapper from './FormWrapper'
import RedPill from './RedPill'
import BluePill from './BluePill'
import BlackPill from './BlackPill'
import { PromoCodeUtils } from 'shared/functions/'
import { usePromoCodes } from 'hooks/usePromoCodes'
import { PromoCode } from 'types'

const PlanSelector: React.FC = () => {
  const { data: promoCodes, isLoading: promoCodesLoading } = usePromoCodes()

  const { options, selectedPlan, onSelectPlan, onSubmitPlan, promoCode, setPromoCode } = useSignUpContext()
  const [promoCodeIsValid, setPromoCodeIsValid] = useState<boolean>(false)
  const [promoCodeStripeData, setPromoCodeStripeData] = useState<PromoCode | null>(null)

  useEffect(() => {
    if (promoCode && promoCodes) {
      console.log(promoCode)
      onPromoCodeChange(promoCode)
      // find the promo codes out of the collection
      const promoCodeTarget = promoCodes.find((promoCodeItem) => promoCodeItem.code === promoCode)
      if (!promoCodeTarget) {
        console.log('promoCodeTarget not found :>> ', promoCodeTarget)
        return
      }
      setPromoCodeStripeData(promoCodeTarget)
      console.log('promoCodeTarget :>> ', promoCodeTarget)
    }
  }, [promoCode])

  const monthlyPlan = options.find((o: { type: string }) => {
    return o.type === 'monthly'
  })
  const annualPlan = options.find((o: { type: string }) => {
    return o.type === 'yearly'
  })
  const quarterlyPlan = options.find((o: { type: string }) => {
    return o.type === 'quarterly'
  })

  const activeMonthly = monthlyPlan && monthlyPlan.type && selectedPlan === monthlyPlan.type
  const activeQuarterly = quarterlyPlan && quarterlyPlan.type && selectedPlan === quarterlyPlan.type
  const activeYearly = annualPlan && annualPlan.type && selectedPlan === annualPlan.type

  const onPromoCodeChange = (code: string) => {
    if (!promoCodes) return
    const isValid = PromoCodeUtils.validatePromoCode(code, promoCodes)
    setPromoCodeIsValid(isValid)
  }
  const renderPromoValidity = () => {
    if (promoCodeIsValid && promoCode !== '') {
      return (
        <div className="text-xs text-green-500 font-bold">
          <span className="text-green-500">✓ </span> {PromoCodeUtils.getDescription(promoCode, promoCodes)}
        </div>
      )
    }
    if (promoCode && !promoCodeIsValid) {
      return (
        <div className="text-xs text-red-500 font-bold">
          <span className="text-red-500">✗</span> Invalid Promo Code
        </div>
      )
    }
    return null
  }

  if (promoCodesLoading || !options) {
    return (
      // show a loading spinner
      <div className="flex justify-center items-center">
        <div className="text-xs font-semibold text-gray-900">Loading...</div>
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
      </div>
    )
  }

  const showMonthlyDiscount = false
  const MonthlyDiscount = '50% OFF!'

  const showQuarterlyDiscount = false
  const QuarterlyDiscount = '45% OFF!'

  const showAnnualDiscount = false
  const AnnualDiscount = '45% OFF!'

  const manualPromoEntryPlan = promoCodeStripeData?.metadata.target
  const manualPromoMonthly = promoCodeStripeData?.metadata.target === 'monthly'
  const manualPromoQuarterly = promoCodeStripeData?.metadata.target === 'quarterly'
  const manualPromoAnnual = promoCodeStripeData?.metadata.target === 'yearly'

  return (
    <FormWrapper step={1} title="Choose your plan!">
      {/* <h2 className="text-xs font-semibold">Start your Lean Journey totally free! Pay nothing today. You will be charged after the trial ends unless cancelled prior!</h2> */}
      <div
        className={`border-2 hover:border-red-800 cursor-pointer border-B5B5B5 shadow-md rounded-3xl p-4 mt-4  ${manualPromoEntryPlan ? 'h-26' : 'h-24'}    ${
          activeMonthly ? 'bg-red-500' : ''
        }  p-4 `}
        onClick={() => {
          if (monthlyPlan) {
            onSelectPlan(monthlyPlan.type)
          }
        }}
      >
        {/* MONTHLY */}

        <div className={` flex flex-col    `}>
          <div className={`flex ${showMonthlyDiscount ? 'center' : 'justify-end '}relative`}>
            {/* <BlackPill active={activeQuarterly || false}>LEANMAS SPECIAL</BlackPill> */}
            {showMonthlyDiscount && (
              <RedPill active={activeMonthly || false} className=" -rotate-6 absolute max-h-7 -mr-2 -mt-2  ">
                {MonthlyDiscount}
              </RedPill>
            )}
          </div>

          <div className={`${activeMonthly ? 'text-slate-50 ' : 'text-slate-900'} flex font-bold ${showMonthlyDiscount ? 'mt-4' : ''}`}>
            {showMonthlyDiscount && (
              <>
                <div className={`${activeMonthly ? 'text-slate-50 line-through' : ' line-through text-slate-900'} text-xs  font-bold mr-1 `}>$0.83/day</div>
                <div className={`${activeMonthly ? 'text-slate-50' : 'text-slate-900'} text-xs  font-bold `}>Only $0.42/day for 6 months</div>
              </>
            )}
            {!showMonthlyDiscount && (
              <>
                <div className={`${activeMonthly ? 'text-slate-50' : 'text-slate-900'} text-xs  font-bold `}>Only $0.83/day</div>
              </>
            )}

            {/* <div className={`text-sm  ${activeMonthly ? 'text-slate-50' : ' text-coral-darkest'} font-bold  italic opacity-70`}> 3 day free trial</div> */}
          </div>
          <div className={`${activeMonthly ? 'text-slate-50' : 'text-slate-900'} `}>
            {showMonthlyDiscount && (
              <span>
                <span className="pl-2 line-through text-2xl tracking-tight font-bold pb-2">$24.99</span>
                <span className="pl-2 text-2xl tracking-tight font-bold pb-2">$12.49 Monthly </span>
              </span>
            )}
            {!showMonthlyDiscount && (
              <span>
                <span className="pl-2 text-2xl tracking-tight font-bold pb-2">$24.99 Monthly</span>
              </span>
            )}
            {showMonthlyDiscount && (
              <>
                <div className={`${activeMonthly ? 'text-slate-50' : 'text-slate-900'} text-xxs  pb-2`}>50% off for 6 months, then renews at $24.99/month</div>
              </>
            )}
            {!showMonthlyDiscount && (
              <>
                <div className={`${activeMonthly ? 'text-slate-50' : 'text-slate-900'} text-xxs  pb-2`}>Renews at $24.99/month</div>
              </>
            )}
          </div>
          {/* {manualPromoEntryPlan === 'monthly' && (
            <div className={`${activeMonthly ? 'text-slate-50' : 'text-slate-900'} text-xxs  pb-2`}>
              <span className="text-sm font-medium ">{PromoCodeUtils.getDescription(promoCode, promoCodes)}</span>
            </div>
          )} */}
        </div>
      </div>

      {/* QUARTERLY */}
      <div
        className={`${activeQuarterly ? 'bg-red-500' : ''} border-2 cursor-pointer hover:border-red-800  border-B5B5B5 shadow-md h-32 rounded-3xl py-2  px-4 mt-3`}
        onClick={() => {
          if (quarterlyPlan) {
            onSelectPlan(quarterlyPlan.type)
          }
        }}
      >
        <div className="flex justify-between">
          <BluePill active={activeQuarterly || false}>Most Popular</BluePill>
          {/* <BluePill active={activeQuarterly || false}>Best Value</BluePill>
          {showQuarterlyDiscount && (
            <RedPill active={activeQuarterly || false} className=" -rotate-6 max-h-7 -mr-2 ">
              {QuarterlyDiscount}
            </RedPill>
          )} */}
        </div>
        <div className={`${activeQuarterly ? 'text-slate-50' : 'text-slate-900'} flex justify-between font-bold`}>
          <div className={`${activeQuarterly ? 'text-slate-50' : 'text-slate-900'} text-xs  `}>
            {showQuarterlyDiscount && (
              <>
                <span className="line-through ">$18.33/month</span>
                <span className="ml-1">Only $9.99/month or $0.33/day</span>
              </>
            )}
            {!showQuarterlyDiscount && (
              <>
                <span className="">Only $18.33/month</span>
              </>
            )}
          </div>
          {/* <div className={`text-sm ${activeQuarterly ? 'text-slate-50' : ' text-coral-darkest'}   font-bold  italic  opacity-70  `}>3 day free trial</div> */}
        </div>
        <div className={`${activeQuarterly ? 'text-slate-50' : 'text-slate-900'} `}>
          {showQuarterlyDiscount && (
            <>
              <span className=" pl-2 line-through text-2xl tracking-tight font-bold pb-2">$54.99</span>
              <span className="pl-2 text-2xl tracking-tight font-bold pb-2">$29.99 Quarterly </span>
            </>
          )}
          {!showQuarterlyDiscount && (
            <>
              <span className=" pl-2 text-2xl tracking-tight font-bold pb-2">$54.99 Quarterly</span>
            </>
          )}

          <div className={`${activeQuarterly ? 'text-slate-50' : 'text-slate-900'} text-xxs  pb-2`}>Renews at $54.99/quarter</div>
        </div>
        {/* <div className={`${activeQuarterly ? 'text-slate-50' : 'text-slate-900'} text-xs font-bold pt-1`}>
          Just $9.99/month for 3 months, billed quarterly
        </div> */}
      </div>

      {/* ANNUAL */}
      <div
        className={`${activeYearly ? 'bg-red-500' : ''} border-2 cursor-pointer hover:border-red-800 shadow-md border-B5B5B5 h-35 rounded-3xl p-4 mt-3`}
        onClick={() => {
          if (annualPlan) {
            onSelectPlan(annualPlan.type)
          }
        }}
      >
        <div className="flex flex-col">
          <div className="flex flex-row  justify-between">
            <BluePill active={activeYearly || false}>Best Value!</BluePill>
            {/* <div className={`text-sm ${activeYearly ? 'text-slate-50' : ' text-coral-darkest'}    font-bold  italic opacity-70  `}>3 day free trial</div> */}
          </div>
        </div>

        {showAnnualDiscount && (
          <div className={`${activeYearly ? 'text-slate-50' : 'text-slate-900'} flex justify-between font-bold mt-2`}>
            <div className={`${activeYearly ? 'text-slate-50' : 'text-slate-900'} text-xs `}>Only $16.99/month</div>
          </div>
        )}
        {!showAnnualDiscount && (
          <div className={`${activeYearly ? 'text-slate-50' : 'text-slate-900'} flex justify-between font-bold mt-2`}>
            <div className={`${activeYearly ? 'text-slate-50' : 'text-slate-900'} text-xs `}>Only $16.99/month</div>
          </div>
        )}

        {showAnnualDiscount && (
          <div className={`${activeYearly ? 'text-slate-50' : 'text-slate-900'} `}>
            <>
              <span className=" pl-2 line-through text-2xl tracking-tight font-bold pb-2">$54.99</span>
            </>
            <span className="pl-2 line-through text-2xl tracking-tight font-bold pb-2">$199.99 Annually </span>
            <span className="pl-2 text-2xl tracking-tight font-bold pb-2">$99.99 Annually </span>
            <div className={`text-xxs  pb-2`}>Renews at $199.99/year</div>
          </div>
        )}
        {!showAnnualDiscount && (
          <div className={`${activeYearly ? 'text-slate-50' : 'text-slate-900'} `}>
            <span className="pl-2 text-2xl tracking-tight font-bold pb-2">$199.99 Annually </span>
            <div className={`${activeYearly ? 'text-slate-50' : 'text-slate-900'} text-xxs  pb-2`}>Renews at $199.99/year</div>
          </div>
        )}
      </div>
      {/* PromoCodes */}
      <div className="mt-4">
        <div className="flex flex-row justify-between">
          <div className="text-xs font-bold">Promo Code</div>
        </div>
        <input
          className="w-full border-2 border-B5B5B5 rounded-md p-2 mt-2"
          type="text"
          placeholder="Enter Promo Code"
          value={promoCode}
          onChange={(e) => {
            setPromoCode(e.target.value)
          }}
        />
        {renderPromoValidity()}
      </div>

      <div className={`mt-4 bg-slate-100 text-xs font-semibold p-2`}>
        <p> Renewed monthly/quarterly/annually. All prices in USD.</p>
      </div>
      <button
        className={`  bg-gradient-to-r  disabled:bg-slate-300 shadow-md hover:ring ring-coral-darkest disabled:ring-0  ${
          selectedPlan ? ' from-coral to-coral-darker text-slate-50  shadow-coral-darkest' : 'text-black'
        } w-full  font-extrabold text-lg my-4  h-12 rounded-md`}
        disabled={!selectedPlan}
        onClick={onSubmitPlan}
      >
        Continue
      </button>
    </FormWrapper>
  )
}
export default PlanSelector
